
::v-deep .el-dialog__wrapper {
    .el-dialog__header {
        background: #2DC079;

        span, i {
            font-size: 16px;
            color: #fff;
        }
    }

    .el-dialog__body {
        padding: 45px 0;
    }

    .el-dialog__footer {
        text-align: center;
    }
}

.dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
        font-size: 50px;
        color: #2DC079;
    }

    .sucess-text {
        margin-top: 18px;
        font-size: 16px;
        color: #333333;
    }
}

