
.home-box{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.select-file{
  margin-right: 10px;
  background-color: #fff;
  &:hover{
    background-color: #1CB064;
    color: #fff;
  }
}
.no-select-file{
  font-size: 14px;
  color: #666;
}
